import { getSessionUser } from '../shared/util/session.util.js';
import { apiFetch } from './api-fetch.js';
// create group method
export function createGroup(userId = getSessionUser().id, body) {
    return apiFetch(`/api/v1/users/${userId}/groups`, 'POST', {
        body,
    }).then((res) => {
        if (res.status == 409) {
            return false;
        }
        if (!res.ok) {
            throw new Error(`Failed to create group, status: ${res.status}`);
        }
        return true;
    });
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} groupId - The ID of the group to retrieve.
 * @returns {Promise<Group>} - A Promise that resolves to the Group.
 */
export function getGroupRootFolder(userId = getSessionUser().id, groupId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/root`, 'GET').then((res) => res.json());
}
export function getGroupFolder(userId = getSessionUser().id, groupId, folderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/${folderId}`, 'GET').then((res) => res.json());
}
export function getGroupRootFolderSlimView(userId = getSessionUser().id, groupId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/root/slim`, 'GET').then((res) => res.json());
}
export function getGroupFolderSlimView(userId = getSessionUser().id, groupId, folderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/${folderId}/slim`, 'GET').then((res) => res.json());
}
export function getGroupListing(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/groups`, 'GET').then((res) => res.json());
}
/**
 * Creates a new folder.
 * @param {string} userId
 * @param {CreateFolderRequest} body - The data for the new deck.
 * @returns {Promise<Boolean>} - A Promise that resolves to a boolean indicating if it successfully saved or violated unique constraint
 */
export function createGroupRootFolder(userId, groupId, body) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders`, 'POST', {
        body,
    }).then((res) => {
        if (res.status == 409) {
            return false;
        }
        if (!res.ok) {
            throw new Error(`Failed to create root folder, status: ${res.status}`);
        }
        return true;
    });
}
/**
 * Creates a new folder.
 * @param {string} userId
 * @param {string} folderId
 * @param {CreateFolderRequest} body - The data for the new deck.
 * @returns {Promise<Boolean>} - A Promise that resolves to a boolean indicating if it successfully saved or violated unique constraint
 */
export function createGroupFolder(userId, groupId, folderId, body) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/${folderId}`, 'POST', {
        body,
    }).then((res) => {
        if (res.status == 409) {
            return false;
        }
        if (!res.ok) {
            throw new Error(`Failed to create folder, status: ${res.status} inside folderId: ${folderId}`);
        }
        return true;
    });
}
/**
 * @param {string} userId
 * @param {string} groupId
 * @returns {Promise<void>} - A Promise that resolves to void.
 */
export function addDeckToGroup(userId, groupId, deckId, folderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/decks`, 'POST', {
        body: { id: deckId, folderId: folderId },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to add deck to group, status: ${res.status}`);
        }
    });
}
export function moveGroupDeck(userId, groupId, deckId, newFolderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/decks/${deckId}/move`, 'POST', {
        body: { newFolderId: newFolderId },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to move deck: ${res.status}`);
        }
    });
}
export function moveGroupFolder(userId, groupId, folderId, newFolderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/${folderId}/move`, 'POST', {
        body: { newFolderId: newFolderId },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to move folder: ${res.status}`);
        }
    });
}
export function forkDeckToSelf(userId, groupId, deckId, folderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/decks/${deckId}/fork`, 'POST', {
        body: {
            folderId: folderId
        }
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to fork deck to self: ${res.status}`);
        }
    });
}
export function getGroupDetails(userId, groupId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/details`, 'GET').then((res) => res.json());
}
export function getPendingMembers(userId, groupId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/invites/pending`, 'GET').then((res) => res.json());
}
export function inviteUsersToGroupByUserIds(userId, groupId, toUserIds) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/invites`, 'POST', {
        body: { toUserIds: toUserIds },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to invite users to group: ${res.status}`);
        }
    });
}
export function inviteUsersToGroupByEmail(userId, groupId, toEmail) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/invites/email`, 'POST', {
        body: { toEmail: toEmail },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to invite user to group by email: ${res.status}`);
        }
    });
}
export function acknowledgeGroupInvite(userId, groupInviteId, acknowledgement) {
    return apiFetch(`/api/v1/users/${userId}/group-invites/${groupInviteId}/acknowledge`, 'POST', {
        body: { acknowledgement: acknowledgement },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to acknowledge group invite: ${res.status}`);
        }
    });
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<GroupListing[]>} - A Promise that resolves to the GroupListing.
 */
export function getGroups(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/groups`, 'GET').then((res) => res.json());
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} groupId - The ID of the group to retrieve.
 * @returns {Promise<Group>} - A Promise that resolves to the Group.
 */
export function getGroup(userId = getSessionUser().id, groupId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}`, 'GET').then((res) => res.json());
}
// /**
//  * @param {string} userId - The ID of the user to retrieve.
//  * @param {string} deckId - The ID of the deck to add to the group.
//  * @returns {Promise<GroupListing[]>} - A Promise that resolves to the GroupListing.
//  */
// export function addDeckToGroup(userId = getSessionUser().id, deckId) {
//   return apiFetch(`/api/v1/users/${userId}/groups`, 'GET', {
//     body: { deckId: deckId },
//   }).then((res) => res.json())
// }
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} groupId - The ID of the group to retrieve.
 * @param {string} folderId - The ID of the folder to retrieve.
 * @returns {Promise<GroupFolder>} - A Promise that resolves to the Folder.
 */
export function getFolder(userId = getSessionUser().id, groupId, folderId) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/${folderId}`, 'GET').then((res) => res.json());
}
/**
 * @param {string} folderId
 * @param {string} userId defaults to current session user
 * @returns
 */
export function deleteFolder(folderId, userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/folders/${folderId}`, 'DELETE');
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} groupName - The name of the groiup to add for the user.
 * @returns {Promise<void>} - A Promise with void.
 */
export function addGroup(userId = getSessionUser().id, groupName) {
    return apiFetch(`/api/v1/users/${userId}/groups`, 'POST', {
        body: { name: groupName },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to create group: ${res.status}`);
        }
    });
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} groupId - The ID of the group to add the folder to.
 * @param {string} folderName - The name of the folder to add to the group.
 * @returns {Promise<void>} - A Promise with void.
 */
export function addFolderToGroup(userId = getSessionUser().id, groupId, folderName) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders`, 'POST', {
        body: { name: folderName },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to create folder for group: ${res.status}`);
        }
    });
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} groupId - The ID of the group to add the folder to.
 * @param {string} folderId - The ID of the folder to add the folder to.
 * @param {string} folderName - The name of the folder to add to the group.
 * @returns {Promise<void>} - A Promise with void.
 */
export function addFolderToFolder(userId = getSessionUser().id, groupId, folderId, folderName) {
    return apiFetch(`/api/v1/users/${userId}/groups/${groupId}/folders/${folderId}/folders`, 'POST', {
        body: { name: folderName },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to create folder within folder: ${res.status}`);
        }
    });
}
