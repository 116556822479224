import { classRegistry, Rect } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
// It is possible that we could extend this to handle a lot more of the burden for YT.Player and the iframe API
// Idk exactly what that would look like, but the canvas container does a lot of heavy lifting to make 
// this YoutubeVideo object work and it may not need to do so much if this could handle some of it. 
class YoutubeVideo extends Rect {
    constructor(options) {
        super(options);
        this.videoId = '';
        this.uniqueId = '';
        this.videoId = options.videoId;
        this.uniqueId = uuidv4();
        // clipTo: function(ctx) {
        //     // This will clip the iframe to the canvas bounds
        //     ctx.rect(0, 0, this.canvas.width, this.canvas.height);
        //   }
    }
    /**
     * Returns object representation of an instance
     * @method toObject
     * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
     * @return {Object} object representation of an instance
     */
    toObject(propertiesToInclude = []) {
        return super.toObject([
            'videoId',
            'uniqueId',
            ...propertiesToInclude
        ]);
    }
}
YoutubeVideo.type = 'YoutubeVideo';
classRegistry.setClass(YoutubeVideo, 'YoutubeVideo');
classRegistry.setSVGClass(YoutubeVideo);
export default YoutubeVideo;
