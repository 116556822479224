var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getSessionUser, deleteSessionUser } from '../shared/util/session.util.js';
export const API_URL = 'https://api.lyonboard.com';
// export const API_URL = 'http://localhost:8080'
/**
 * @typedef {Object} HttpMethods
 * @property {'GET'} GET
 * @property {'POST'} POST
 * @property {'PUT'} PUT
 * @property {'DELETE'} DELETE
 */
/** @type {HttpMethods} */
export const Methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};
/**
 * @param {string} path
 * @param {"GET"|"POST"|"PUT"|"DELETE"} method
 * @param {Object} [settings]
 * @param {Object} [settings.body]
 * @param {string} [settings.API]
 * @param {Object} [settings.headers] - Additional headers to include in requezst, or override default headers.
 * @returns {Promise<Response>}
 */
export function apiFetch(path_1, method_1) {
    return __awaiter(this, arguments, void 0, function* (path, method, { body = null, API = API_URL, headers = {} } = {}) {
        /** @type {Object<string, *>} */
        const options = {
            method,
            headers: Object.assign({ 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json', Accept: 'application/json' }, headers),
        };
        // const sessionUserToken = getSessionUser()?.token
        // if (sessionUserToken) options.headers['Authorization'] = `Bearer ${sessionUserToken}`
        if (body instanceof FormData) {
            options.body = body;
            options.headers['Content-Type'] = 'multipart/form-data';
        }
        else if (method !== Methods.GET && body) {
            options.body = JSON.stringify(body);
        }
        options.credentials = 'include';
        // 
        const res = yield fetch(API + path, options);
        if (res.ok) {
            return res;
        }
        if (res.status === 401) {
            // Optionally clear any local state or session storage
            // sessionStorage.removeItem('user');
            deleteSessionUser();
            // Redirect to login page
            window.location.href = '/login';
            // Optionally return or throw an error to prevent further processing
            throw new Error('Unauthorized');
        }
        else {
            throw /** @type {{message: string, res: *}} */ {
                message: (yield res.json()).message || res.statusText,
                res,
            };
        }
    });
}
