var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { html, css } from 'lit';
import RouterMixin, { go } from './shared/router/router-mixin.js';
import './shared/components/app-header.js';
import * as fabric from 'fabric'; // v6
import { wrapFabric } from './smartboard/wrapFabric.js';
import './performance.js';
import { routes } from './shared/router/routes.js';
import { groupsIcon, homeIcon, decksIcon, plus } from './design/icon/actions.js';
import globalStyles from './design/styles/global-styles.js';
import { getSessionUser } from './shared/util/session.util.js';
import './views/group/create-group-menu.js';
import { getGroupListing } from './api/groups.service.js';
wrapFabric(fabric);
class AppEnter extends RouterMixin {
    constructor() {
        super();
        this.groups = [];
        this.showGroups = false;
        this.showCreateGroup = false;
        this._boundTurnOffCreateGroup = this.turnOffCreateGroup.bind(this);
        this._boundRefreshGroups = this.refreshGroups.bind(this);
    }
    connectedCallback() {
        super.connectedCallback();
        window.perf.Info('AppEnter.connectedCallback');
        document.addEventListener('groupCreated', this._boundRefreshGroups);
        document.addEventListener('closeGroupMenu', this._boundTurnOffCreateGroup);
    }
    disconnectedCallback() {
        document.removeEventListener('groupCreated', this._boundRefreshGroups);
        document.removeEventListener('closeGroupMenu', this._boundTurnOffCreateGroup);
        super.disconnectedCallback();
    }
    toggleGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            this.showGroups = !this.showGroups;
            if (this.groups.length <= 0) {
                this.groups = yield getGroupListing(getSessionUser().id);
            }
        });
    }
    refreshGroups() {
        return __awaiter(this, void 0, void 0, function* () {
            this.groups = yield getGroupListing(getSessionUser().id);
        });
    }
    turnOnCreateGroup() {
        this.showCreateGroup = true;
    }
    turnOffCreateGroup() {
        this.showCreateGroup = false;
    }
    render() {
        return html `
      <div class="app-container">
      ${this.showHeader ? html `
        <div class="left-bar">
          <div class="top-bar-left">
            <h1 class="title" @click=${() => go(routes.DECKS.path, {})}>LB</h1>
          </div>

          <div class="home bar-icon-container">
            <div class="bar-icon" @click=${() => go(routes.DECKS.path, {})}>${homeIcon}</div>
            <div class="bar-icon-label">Home</div>
          </div>

          <div class="decks bar-icon-container">
            <div class="bar-icon" @click=${() => go(routes.DECKS.path, {})}>${decksIcon}</div>
            <div class="bar-icon-label">Decks</div>
          </div>

          <div class="decks bar-icon-container">
            <div class="bar-icon" @click=${() => this.toggleGroups()}>${groupsIcon}</div>
            <div class="bar-icon-label">Groups</div>
          </div>
        </div>` : ''}

      ${this.showGroups ? html `
        <div class="group-content">
          <div class="group-header-container">
            <div class="groups-header">
              Groups
            </div>
            <div class="create-group-button" @click=${() => this.turnOnCreateGroup()}>
              ${plus}
            </div>
          </div>
          
          <div class="group-list">
            ${(this.groups || []).map((group) => html `
                  <div class="group" @click=${() => go(routes.GROUP.path, { groupId: group.groupId })}>
                    <div class="group-icon">${groupsIcon}</div>
                    <div class="group-name">${group.name}</div>
                  </div>`)}
          </div>

        </div>` : ''}

        ${this.showCreateGroup ? html `
          <create-group-menu></create-group-menu>
        ` : ''}

        <div class="content">
          <slot></slot>
        </div>
      </div>
    `;
    }
}
AppEnter.properties = Object.assign(Object.assign({}, RouterMixin.properties), { showGroups: { type: Boolean }, groups: { type: Array }, showCreateGroup: { type: Boolean } });
AppEnter.styles = [globalStyles, css `

    .group-content {
      display: flex;
      flex-direction: column;
      width: 250px;
      height: 100vh;
      background-color: #F5F5F5;
      border-right: 1px solid #D5D7DA;
      user-select: none;
    }

    .groups-header {

      font-size: 24px;
    }

    .group-list {
      margin-top: 20px;
    }

      
    .group {
      display: flex;
      flex-direction: row;
      padding: 10px;
      padding-left: 10px;
      align-items: center;
      user-select: none;
    }

    .group:hover {
      background-color: #E9EAEB;
    }

    .group-name {
      margin-left: 10px;
    }

    .group-header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 20px;
      margin-left: 20px;
    }

    .create-group-button {
      margin-left: 7px;
      padding: 3px;
      background-color: #E9EAEB;
      border-radius: 10px;
    }

    .create-group-button:hover {
      background-color: #D5D7DA;
    }


    :host {
      -webkit-tap-highlight-color: transparent;
      display: block;
      height: 100%;
    }

    .home {
      margin-top: 40px;
    }

    .bar-icon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      user-select: none;
    }

    svg {
      display: block;
    }

    .decks {
      margin-top: 20px;
    }

    .bar-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;
      padding: 8px;
      background-color: #E9EAEB;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      font-family: 'Roboto', sans-serif;
      cursor: pointer;
    }

    .top-bar-left {
      display: flex;
    }

    .left-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-color: #F5F5F5;
      max-width: 100px;
      min-width: 70px;
      height: 100vh;
      overflow: hidden;
      border-right: 1px solid #D5D7DA;
    }

    .app-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
    }

    .content {
      display: flex;
      height: 100%;
      width: 100%;
    }

    slot {
      width: 100%;
    }


    .profile {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
    .profile-picture {
      cursor: pointer;
    }
  `];
customElements.define('app-start', AppEnter);
export default AppEnter;
