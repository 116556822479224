var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//@ts-nocheck
import { LitElement, html, css } from 'lit';
import globalStyles from '../../design/styles/global-styles.js';
import { moveDeckToFolder, getMovingFolderModelForUser, getMovingRootFolderModelForUser } from '../../api/users.service.js';
import { getSessionUser } from '../../shared/util/session.util.js';
import { createGroup } from '../../api/groups.service.js';
import { uploadIcon, home, rightChevron, singleUser, friends, logout } from '../../design/icon/actions.js';
class CreateGroupMenu extends LitElement {
    constructor() {
        super();
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    createGroup() {
        return __awaiter(this, void 0, void 0, function* () {
            this.createDeckError = false;
            const input = this.shadowRoot.querySelector('.group-name-input-box');
            const name = input ? input.value : '';
            try {
                const successful = yield createGroup(getSessionUser().id, { "name": name });
                if (successful) {
                    this.closeCreateGroupPrompt();
                }
                else {
                }
            }
            catch (error) {
                this.createGroupError = true;
                console.log('Group creation failed:', error);
                // Optionally, display an error message to the user
            }
            finally {
                this.closeMenu();
            }
            this.dispatchEvent(new CustomEvent('groupCreated', {
                detail: { name: name },
                bubbles: true,
                composed: true,
            }));
        });
    }
    closeMenu() {
        this.dispatchEvent(new CustomEvent('closeGroupMenu', { bubbles: true, composed: true }));
    }
    render() {
        return html `
        <div class="overlay" @click=${this.closeMenu}></div>

        <div class="menu">
            <div class="menu-contents">
                <div class="header">
                    Create Group
                </div>
                <div class="submit-section">
                    <div class="group-name-input">
                        <input class="group-name-input-box"></input>
                    </div>
                    <div class="buttons">
                        <div class="group-cancel" @click=${this.closeMenu}>
                            Cancel
                        </div>
                        <div class="group-submit" @click=${() => this.createGroup()}>
                            Create
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
    }
}
CreateGroupMenu.properties = {
    showUserProfileSettings: { type: Boolean },
};
// transition: background-color 0.05s;
// cursor: pointer;
CreateGroupMenu.styles = [
    globalStyles,
    css `

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000; /* Just below the prompt */
    }

    .menu {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 10px;
        width: 300px;
        z-index: 1001;
    }

    .menu-contents {
        display: flex;
        flex-direction: column;
        padding: 15px;
    }

    .header {
        font-size: 20px;
        font-weight: 500;
    }

    .submit-section {
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: end;
        justify-content: end;
        margin-top: 10px;
    }

    .group-name-input {
        display: flex;
        margin-top: 15px;
    }

    .group-name-input-box {
      border-radius: 10px;
      border: 1px solid #E9EAEB;
      font-size: 16px;
      padding: 10px;
      display: flex;
      flex-grow: 1;
    }

    .group-submit {
      background-color: #7F56D9;
      color: white;
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
    }

    .group-submit:hover {
      background-color: #53389E;
      cursor: pointer;
    }

    .group-cancel {
      color: black;
      padding: 10px 15px;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 10px;
    }

    .group-cancel:hover {
      background-color: #E9EAEB;
      cursor: pointer;
    }

    `,
];
customElements.define('create-group-menu', CreateGroupMenu);
export default CreateGroupMenu;
