import { css } from 'lit';
import labelStyles from './label.styles.js';
const formGlobalStyles = css `
  form {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  form > input {
    margin: 0px 0px 10px 0px;
  }

  form > label {
    margin: 3px;
  }

  form > button {
    width: 100%;
    margin: 30px 0px;
  }
  /** TEXTAREAS */
  textarea {
    height: 100px;
  }

  /** Layout */
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
  /** any row items  should have the same width */
  .row > * {
    flex: 1;
  }
`;
export default formGlobalStyles;
