import * as fabric from 'fabric'; // v6
import CustomTextbox from '../shared/fabric/CustomTextbox.js';
/**
 * @typedef {Object} SFabricSettings
 * @property {Object<string, HTMLImageElement>} SVGCache
 */
// /**
//  * @typedef {import('fabric').fabric & SFabricSettings} SFabric
//  */
import { createCustomControls } from './controls-setup.js';
import YoutubeVideo from '../shared/fabric/YoutubeVideo.js';
/**
 * @param {typeof window.fabric} fabric
 * @returns {typeof window.fabric}
 */
export function wrapFabric(fabric) {
    const sfabric = Object.assign(Object.assign({}, fabric), { SVGCache: {}, CustomTextbox: CustomTextbox, YoutubeVideo: YoutubeVideo });
    window.fabric = sfabric;
    // createCustomControls(fabric.Textbox.prototype.controls)
    // createCustomControls(fabric.Object.prototype.controls)
    createCustomControls();
    return sfabric;
}
