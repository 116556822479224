import { css } from 'lit';
const inputGlobalStyles = css `
  input {
    display: block;
    height: 50px;
    width: 100%;
    padding: 0.5em;
    background-color: white;
    margin: 0 20px;
    box-sizing: border-box;
    border: 0.15em solid lightgray;
    border-radius: 5px;
    font-size: 1em;
    font-family: Arial, sans-serif;
  }
  input:focus {
  }
  input.sm {
  }
  input.lg {
  }
  input[invalid] {
    border: 1px solid red;
  }
  input[disabled] {
    opacity: 0.7;
  }
  input[type='file'] {
    border: none;
  }
  label {
    color: var(--Grey, #737373);
    font-family: 'inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  @media only screen and (max-width: 1024px) {
    button {
    }
  }

  @media only screen and (max-width: 768px) {
    button {
    }
  }

  @media only screen and (max-width: 480px) {
    button {
    }
  }
`;
export default inputGlobalStyles;
