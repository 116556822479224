import { getSessionUser } from '../shared/util/session.util.js';
import { apiFetch } from './api-fetch.js';
import { routes } from '../shared/router/routes.js';
import { go } from '../shared/router/router-mixin.js';
/**
 * Retrieves a user.
 * @returns {Promise<User>} - A Promise that resolves to the User.
 */
export function getUserNoId() {
    return apiFetch(`/api/v1/users`, 'GET').then((res) => res.json());
}
/**
 * Retrieves a user.
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<User>} - A Promise that resolves to the User.
 */
export function getUser(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}`, 'GET').then((res) => res.json());
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<UserFolder>} - A Promise that resolves to the Folder.
 */
export function getRootFolderForUser(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/folders/root`, 'GET').then((res) => res.json());
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} folderId - The ID of the user to retrieve.
 * @returns {Promise<UserFolder>} - A Promise that resolves to the Folder.
 */
export function getFolderForUser(userId = getSessionUser().id, folderId) {
    return apiFetch(`/api/v1/users/${userId}/folders/${folderId}`, 'GET').then((res) => res.json());
}
/**
 * Creates a new folder.
 * @param {string} userId
 * @param {CreateFolderRequest} body - The data for the new deck.
 * @returns {Promise<Boolean>} - A Promise that resolves to a boolean indicating if it successfully saved or violated unique constraint
 */
export function createRootFolder(userId, body) {
    return apiFetch(`/api/v1/users/${userId}/folders`, 'POST', {
        body,
    }).then((res) => {
        if (res.status == 409) {
            return false;
        }
        if (!res.ok) {
            throw new Error(`Failed to create root folder, status: ${res.status}`);
        }
        return true;
    });
}
/**
 * Creates a new folder.
 * @param {string} userId
 * @param {string} folderId
 * @param {CreateFolderRequest} body - The data for the new deck.
 * @returns {Promise<Boolean>} - A Promise that resolves to a boolean indicating if it successfully saved or violated unique constraint
 */
export function createFolder(userId, folderId, body) {
    return apiFetch(`/api/v1/users/${userId}/folders/${folderId}/folders`, 'POST', {
        body,
    }).then((res) => {
        if (res.status == 409) {
            return false;
        }
        if (!res.ok) {
            throw new Error(`Failed to create folder, status: ${res.status} inside folderId: ${folderId}`);
        }
        return true;
    });
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @param {string} folderId - The ID of the user to retrieve.
 * @returns {Promise<MovingFoldersModel>} - A Promise that resolves to the Folder.
 */
export function getMovingFolderModelForUser(userId = getSessionUser().id, folderId) {
    return apiFetch(`/api/v1/users/${userId}/folders/${folderId}/slim`, 'GET').then((res) => res.json());
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<MovingFoldersModel>} - A Promise that resolves to the Folder.
 */
export function getMovingRootFolderModelForUser(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/folders/root/slim`, 'GET').then((res) => res.json());
}
/**
 * Creates a new folder.
 * @param {string} userId
 * @param {string} deckId
 * @param {MoveDeckToFolderRequest} body - The data for the new deck.
 * @returns {Promise<void>} - A Promise that resolves to a boolean indicating if it successfully saved or violated unique constraint
 */
export function moveDeckToFolder(userId, deckId, body) {
    return apiFetch(`/api/v1/users/${userId}/decks/${deckId}/move`, 'POST', {
        body,
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to move deck: ${res.status}`);
        }
    });
}
/**
 * Creates a new folder.
 * @param {string} userId
 * @param {string} folderId
 * @param {MoveDeckToFolderRequest} body - The data for the new deck.
 * @returns {Promise<void>} - A Promise that resolves to a boolean indicating if it successfully saved or violated unique constraint
 */
export function moveFolderToFolder(userId, folderId, body) {
    return apiFetch(`/api/v1/users/${userId}/folders/${folderId}/move`, 'POST', {
        body,
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to move deck: ${res.status}`);
        }
    });
}
/**
 * Retrieves a user's friend requests.
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<FriendRequest[]>} - A Promise that resolves to the User.
 */
export function getNotifications(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/notifications`, 'GET').then((res) => res.json());
}
/**
 * @param {string} userId - The ID of the user to retrieve.
 * @returns {Promise<Image[]>} - A Promise that resolves to the User.
 */
export function getImages(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/images`, 'GET').then((res) => res.json());
}
// /**
//  * @param {string} userId - The ID of the user to retrieve.
//  * @returns {Promise<Image[]>} - A Promise that resolves to the User.
//  */
export function getGoogleSlidesPresentations(userId = getSessionUser().id) {
    return apiFetch(`/api/v1/users/${userId}/google/presentations`, 'GET').then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to get Google Slides presentations: ${res.status}`);
        }
        return res.json();
    });
}
export function convertGoogleSlidesPresentations(userId = getSessionUser().id, presentationId) {
    return apiFetch(`/api/v1/users/${userId}/google/presentations/${presentationId}/convert`, 'POST', {}).then((res) => res.json());
}
export function submitFeedback(userId = getSessionUser().id, deckId, cardId, feedback) {
    return apiFetch(`/api/v1/users/${userId}/feedback`, 'POST', {
        body: {
            "deckId": deckId, // could be null
            "cardId": cardId, // could be null
            "feedback": feedback,
        },
    }).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to submit feedback: ${res.status}`);
        }
    });
}
export function logoutFromBackend() {
    return apiFetch('/auth/logout', 'POST', {}).then((res) => {
        if (!res.ok) {
            throw new Error(`Failed to logout: ${res.status}`);
        }
        go(routes.LOGIN.path);
    });
}
