import { css } from 'lit';
const labelStyles = css `
  label {
    display: block;
    color: gray;
    text-align: left;
  }
  label[show-required]:after {
    content: ' *';
    color: red;
  }
`;
export default labelStyles;
