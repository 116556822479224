import { css } from 'lit';
import inputStyles from './input.styles.js';
import formStyles from './form.styles.js';
import labelStyles from './label.styles.js';
const globalStyles = css `
  ${labelStyles}
  ${formStyles}
  h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;

    letter-spacing: 1.25px;
    text-transform: uppercase;
  }

  .dialog-title {
    color: var(--Black, #454545);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .card {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    padding: 16px;
    margin: 16px;
  }
  .deck-card {
    display: flex;
    width: 180px;
    height: 120px;
    padding: 16px;
    flex-direction: column;
    align-items: left;
    overflow: hidden;
    gap: 4px;
    border-radius: 8px;
    background: var(--Purple3, #f8f8ff);
    border: 2px solid var(--folder-primary, #db8d7e);
  }
  .group-card {
    display: flex;
    width: 180px;
    height: 120px;
    flex-direction: column;
    align-items: left;
    overflow: hidden;
    gap: 4px;
    border-radius: 8px;
    background: var(--Purple3, #f8f8ff);
    border: 2px solid var(--folder-primary, #737373);
  }
  .group-folder {
    display: flex;
    width: 180px;
    height: 120px;
    flex-direction: column;
    align-items: left;
    overflow: hidden;
    gap: 4px;
    border-radius: 8px;
    background: var(--Purple3, #f8f8ff);
    border: 2px solid var(--folder-primary, #737373);
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.5vw;
    width: 1.5vw;
    background: var(--app-primary);
    border-radius: 50%;
  }

  input[type='range']::-moz-range-thumb {
    height: 1.5vw;
    width: 1.5vw;
    background: var(--app-primary);
    border-radius: 50%;
  }
  input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: 2vw;
    border-radius: 15px;
    border: 2px solid var(--app-light-grey);
    background: transparent;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
  input[type='range']:hover {
    opacity: 1;
  }

  [hide] {
    display: none;
  }

  * {
    font-family: 'Inter', sans-serif;
  }
`;
export default globalStyles;
