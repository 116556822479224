import { routes } from './routes.js';
// /** @type {PermissionCheck} */
// export function userIsLoggedIn(options) {
//   return options?.sessionUser?.token ? null : routes.LOGIN
// }
/** @type {PermissionCheck} */
export function userIsAdmin(options) {
    // TODO: get user permissions for admin from login data
    return true ? null : routes.LOGIN;
}
