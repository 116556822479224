var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-nocheck
import { CanvasEvents } from '../shared/fabric/fabric.events.js';
export const ControlTypes = {
    circle: 'circle',
    rect: 'rect',
    objectToolbar: 'objectToolbar',
};
/**
 * @param {string} src
 * @returns {Promise<HTMLImageElement>}
 */
function loadImage(src) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.src = src;
            img.onload = () => resolve(img);
            img.onerror = reject;
        });
    });
}
/**
 * @param {SFabric} fabric
 * @param {string} src
 */
export function loadAndCacheSvg(fabric, src) {
    loadImage(src).then((/** @type {HTMLImageElement} */ img) => {
        fabric.SVGCache[src] = img;
    });
}
export function createCustomControls() {
    // Gotta load whatever svg we usin
    loadAndCacheSvg(fabric, './design/svg/Link.svg');
    loadAndCacheSvg(fabric, './design/svg/rotate-object.svg');
    var linkControl = new window.fabric.Control({
        x: -0.4,
        y: -0.4,
        zIndex: 1,
        sizeX: 32, // sizeX/Y controls the clickable region for the icon, not the actual size of the icon
        sizeY: 32, // 46 is a magic number that works for the icons in the container
        // offsetX: 32,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, transform, x, y) => {
            if (transform.target.smartboard.link) {
                window.open(transform.target.smartboard.link);
            }
        },
        render: function (ctx, left, top, styleOverride, fabricObject) {
            // for any custom actions before shared render
            if (fabricObject.smartboard && !fabricObject.smartboard.link) {
                return;
            }
            const cornerSize = styleOverride.cornerSize || fabricObject.cornerSize;
            const xSize = 32 || cornerSize;
            const ySize = 32 || cornerSize;
            const xSizeBy2 = xSize / 2;
            const ySizeBy2 = ySize / 2;
            ctx.drawImage(fabric.SVGCache['./design/svg/Link.svg'], left - xSizeBy2, top - ySizeBy2, xSize, ySize);
            // // ctx.save()
            // ctx.strokeStyle = 'purple'
            // ctx.lineWidth = 2
            // // Translate to the object
            // // ctx.translate(this.left, this.top)
            // // math comes from the rotate documentation :)
            // ctx.rotate(this.angle * Math.PI / 180)
            // ctx.strokeRect(
            //     -1, // x position
            //     -1, // y position
            //     this.width + 3, // width
            //     this.height + 3 // height
            // );
            // ctx.restore()
        }
    });
    var rotateControl = new window.fabric.Control({
        // x: 0.5 puts it on the right edge; y: -0.5 puts it at the top edge.
        x: 0.5,
        y: -0.5,
        // Offset it a bit upward (or sideways) to separate from the scaling control.
        offsetY: -20,
        // Set the cursor and action name as needed.
        cursorStyle: 'crosshair',
        actionName: 'rotate',
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        render: function (ctx, left, top, styleOverride, fabricObject) {
            const controlSize = 24;
            // Draw the image centered on (left, top)
            ctx.drawImage(fabric.SVGCache['./design/svg/rotate-object.svg'], left - controlSize / 2, top - controlSize / 2, controlSize, controlSize);
        }
        // Use the default circle rendering (or provide your own render function).
        // render: fabric.controlsUtils.renderCircleControl,
    });
    fabric.Object.createControls = () => {
        const controls = fabric.controlsUtils.createObjectDefaultControls();
        delete controls.mtr; //this gets rid of the rotation point. We want it but this is example for future if necessary
        return {
            controls: Object.assign(Object.assign({}, controls), { rotateControl: rotateControl, linkOpener: linkControl })
        };
    };
    return;
}
